<template>
  <header class="absolute w-full z-30 page-header bg-white" >
    <div class="max-w-7xl mx-auto px-4 sm:px-6">
      <div class="flex items-center justify-between h-navbar-mobile md:h-navbar">

        <!-- Site branding -->
        <div class="shrink-0 lg:mr-2">
          <!-- Logo -->
          <NuxtLink class="text-white flex items-center transition duration-150 ease-in-out" :to="token ? localePath('/dashboard') : localePath('/')" no-prefetch :aria-label="$t('soundmadeseen')">
            <img src="~/assets/img/logo-light.svg" class="w-auto h-6" width="850" height="100" alt="Logo" />
          </NuxtLink>
        </div>

        <!-- Desktop navigation -->
        <nav class="hidden md:flex md:grow">

          <!-- Desktop menu links -->
          <ul class="flex grow justify-start flex-wrap items-center py-1 text-black-900 text-sm font-bold gap-6 lg:gap-10 ">
            <li>
              <NuxtLink :to="localePath('/pricing')" no-prefetch class="header-link">{{ $t('pricing') }}</NuxtLink>
            </li>
            <li>
              <NuxtLink :to="localePath('/articles')" no-prefetch class="header-link">{{ $t('blog') }}</NuxtLink>
            </li>
            <li>
              <NuxtLink :to="localePath('/free-tools')" no-prefetch class="header-link">{{ $t('free.tools') }}</NuxtLink>
            </li>
            <li class="hidden lg:inline-block">
                <NuxtLink :to="localePath('/faqs')" no-prefetch class="header-link">{{ $t('faqs') }}</NuxtLink>
            </li>
             <li>
                <NuxtLink :to="localePath('/support')" no-prefetch class="header-link">{{ $t('support') }}</NuxtLink>
            </li>
          </ul>

          <ul v-if="token" class="flex grow justify-end items-center gap-4 py-1 text-black-900 text-sm font-bold">
            <li>
              <NuxtLink :to="localePath('/dashboard')" no-prefetch class="header-link no-opacity">
                {{ $t('my.dashboard') }}</NuxtLink>
            </li>
            <li>
              <NuxtLink :to="localePath('/account')" no-prefetch class="bg-brand-500 text-white text-sm font-bold rounded-s py-2 px-3">
                {{ $t('account') }}</NuxtLink>
            </li>
          </ul>
          <!-- Desktop sign in links -->
          <ul v-else class="flex grow justify-end flex-wrap items-center gap-4 py-1 text-black-900 text-sm font-bold" >
            <li>
              <NuxtLink :to="localePath('/signin')" no-prefetch class="header-link no-opacity">{{ $t('sign-in') }}</NuxtLink>
            </li>
            <li>
              <NuxtLink :to="localePath('/signup')" no-prefetch class="bg-brand-500 text-white text-sm font-bold rounded-s py-2 px-3">{{ $t('start.for.free') }}</NuxtLink>
            </li>

          </ul>
        </nav>

        <!-- Mobile menu -->
        <div class="flex flex-shrink-0 md:hidden gap-3">
          <NuxtLink v-if="! mobileNavOpen && ! token" :to="localePath('/signup')" no-prefetch class="bg-brand-500 text-white text-sm font-bold rounded-s py-2 px-3">{{ $t('start.free') }}</NuxtLink>
          <!-- Hamburger button -->
          <button class="hamburger flex-shrink-0" ref="hamburger" :class="{ active: mobileNavOpen }" aria-controls="mobile-nav" :aria-expanded="mobileNavOpen" @click="mobileNavOpen = !mobileNavOpen">
             <span class="sr-only">Menu</span>
             <svg xmlns="http://www.w3.org/2000/svg" v-if="!mobileNavOpen" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <mask id="mask0_16_1793" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                <rect width="24" height="24" fill="#D9D9D9"/>
              </mask>
              <g mask="url(#mask0_16_1793)">
                <path d="M2.79663 18.293V16.018H21.2031V18.293H2.79663ZM2.79663 13.1375V10.8625H21.2031V13.1375H2.79663ZM2.79663 7.98203V5.70703H21.2031V7.98203H2.79663Z" fill="black"/>
              </g>
            </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <mask id="mask0_36_3995" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                <rect width="24" height="24" fill="#D9D9D9"/>
              </mask>
              <g mask="url(#mask0_36_3995)">
                <path d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z" fill="#1C1B1F"/>
              </g>
            </svg>
          </button>

          <!-- Mobile navigation -->
          <transition
            enter-active-class="transition ease-out duration-200 transform"
            enter-from-class="opacity-0 -translate-y-2"
            enter-to-class="opacity-100 translate-y-0"
            leave-active-class="transition ease-out duration-200"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <nav
              id="mobile-nav"
              ref="mobileNav"
              v-show="mobileNavOpen"
              class="absolute top-full h-dvh pb-16 pt-6 z-20 left-0 w-full overflow-scroll thin-scrollbar flex flex-col justify-between bg-grey-100"
            >
              <ul class="px-4 text-dark-900 text-mobile-nav opacity-70">
                <li>
                  <NuxtLink :to="localePath('/pricing')"
                            @click="closeMobileNav"
                            no-prefetch
                            class="flex font-medium hover:text-blue-600 py-4">{{ $t('pricing') }}</NuxtLink>
                </li>

                <li>
                  <NuxtLink :to="localePath('/articles')"
                            @click="closeMobileNav"
                            no-prefetch
                            class="flex font-medium hover:text-blue-600 py-4">{{ $t('blog') }}</NuxtLink>
                </li>
                <li>
                  <NuxtLink :to="localePath('/faqs')"
                            @click="closeMobileNav"
                            no-prefetch
                            class="flex font-medium hover:text-blue-600 py-4">{{ $t('faqs') }}</NuxtLink>
                </li>
                <li>
                  <NuxtLink v-if="! token" :to="localePath('/free-tools')"
                            @click="closeMobileNav"
                            no-prefetch
                            class="flex font-medium hover:text-blue-600 py-4">{{ $t('free.tools') }}</NuxtLink>
                </li>
                <li>
                  <NuxtLink :to="localePath('/roadmap')"
                            @click="closeMobileNav"
                            no-prefetch
                            class="flex font-medium hover:text-blue-600 py-4">{{ $t('roadmap') }}</NuxtLink>
                </li>
                <li>
                  <NuxtLink :to="localePath('/support')"
                            @click="closeMobileNav"
                            no-prefetch
                            class="flex font-medium hover:text-blue-600 py-4">{{ $t('support') }}</NuxtLink>
                </li>

              </ul>
             <div v-if="! token" class="flex gap-4 items-center text-center px-4">
                <NuxtLink :to="localePath('/signin')"
                          @click="closeMobileNav" no-prefetch
                          class="bg-white border border-grey-500 text-dark-900 text-sm font-bold rounded-s w-1/2 py-2">{{ $t('sign-in') }}</NuxtLink>
                <NuxtLink :to="localePath('/signup')"
                          @click="closeMobileNav" no-prefetch
                          class="bg-brand-500 text-white text-sm font-bold rounded-s w-1/2 py-2">{{ $t('start.for.free') }}</NuxtLink>
              </div>
              <div v-else class="flex gap-4 items-center text-center px-4">
                  <NuxtLink :to="localePath('/account')"
                            @click="closeMobileNav" no-prefetch
                            class="bg-white border border-grey-500 text-dark-900 text-sm font-bold rounded-s w-1/2 py-2">{{ $t('account') }}</NuxtLink>
                  <NuxtLink :to="localePath('/dashboard')"
                            @click="closeMobileNav" no-prefetch
                            class="bg-brand-500 text-white text-sm font-bold rounded-s w-1/2 py-2">{{ $t('my.dashboard') }}</NuxtLink>
                </div>
            </nav>
          </transition>

        </div>

      </div>
    </div>
  </header>
</template>

<script setup>
import {storeToRefs} from "pinia";
import {useCookieStore} from "~/store/cookie";

const {bgColor} = defineProps({
  bgColor: {
    type: String,
    default: ''
  }
});
const config = useRuntimeConfig();
const mobileNavOpen = ref(false)
const mobileNav = ref(null)
const hamburger = ref(null);

const localePath = useLocalePath();
const cookieStore = useCookieStore();

const closeMobileNav = () => {
  mobileNavOpen.value = false;
}

const {token} = storeToRefs(cookieStore);

// close on click outside
const clickHandler = ({ target }) => {
  if (!mobileNavOpen.value || mobileNav.value.contains(target) || hamburger.value.contains(target)) return
  mobileNavOpen.value = false
}

// close if the esc key is pressed
const keyHandler = ({ keyCode }) => {
  if (!mobileNavOpen.value || keyCode !== 27) return
  mobileNavOpen.value = false
}

onMounted(() => {
  //document.addEventListener('click', clickHandler)
  document.addEventListener('keydown', keyHandler)
})

onUnmounted(() => {
  document.removeEventListener('click', clickHandler)
  document.removeEventListener('keydown', keyHandler)
})

</script>

<style scoped>
.header-link {
  padding-top: 16px;
  padding-bottom: 13px;
  opacity: 0.7;
  transition: all 0.3s;
  border-bottom: 3px solid #ffffff;
}
.header-link.no-opacity {
  opacity: 1;
}
.header-link:hover,
.header-link.router-link-active
{
  padding-bottom: 13px;
  border-bottom: 3px solid #604CDD;
  opacity: 1;
}
</style>